import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Image, Row } from "react-bootstrap";
import SLUGS from "../../resources/slugs";
import { Link } from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
    container: {
        textAlign: "center",
        marginLeft: 32,
        marginRight: 32,
        cursor: "pointer",
    },
    title: {
        ...theme.typography.cardTitle,
        color: theme.color.primaryColor,
        opacity: 0.7,
        marginLeft: 12,
    },
}));

function LogoComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <Row
            className={classes.container}
            horizontal="center"
            vertical="center"
        >
            <Link to={SLUGS.dashboard}>
                <Image
                    width={"10%"}
                    src={require("../../assets/logobig.png")}
                />
                <span className={classes.title}>Dashboard</span>
            </Link>
        </Row>
    );
}

export default LogoComponent;
