const color = {
    blue:'#004BA8',
    red:'#8c001a',
    gray:'#dee2e6',
    grayishBlue: '#A4A6B3',
    grayishBlue2: '#9fa2b4',
    grayishBlue3: '#bdc3c7',
    white:'#fff',
    darkColor:'#404756',
    bgcolor:'#404756',
    bgContent:'#F0F3FC',
    primaryColor:'#F0F3FC',
    hoverColor:'#FFD23F',
};
const button = {
    button: {
        '& .btn-primary': {
          backgroundColor:'transparent',
          borderColor:'transparent',
          color:color.bgContent
        },
        '& .btn-primary.dropdown-toggle':{
            backgroundColor:'transparent',
            borderColor:'transparent',
        },
        '& .btn-primary.dropdown-toggle:focus':{
            color:color.primaryColor,
            boxShadow:'none',
            backgroundColor:color.hoverColor
        }
    }
};
const typography = {
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 19,
        lineHeight: '24px',
        letterSpacing: '0.4px'
    },
    smallSubtitle: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.1px'
    },
    link: {
        fontWeight: '600',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.2px',
        color: color.lightBlue,
        textAlign: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            color: color.grayishBlue
        }
    },
    itemTitle: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: 0.2
    },
    tableTitle:{
        fontWeight: '600',
        fontSize: 15,
        lineHeight: '30px',
        letterSpacing: 0.3
    },
    title: {
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: '30px',
        letterSpacing: 0.3
    }
};

export default {
    // https://www.colorhexa.com/A4A6B3
    color,
    typography,
    button
};