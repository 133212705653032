export default {
    // private
    dashboard: "/dashboard",
    post: "/post",
    addpost: "/post/add",
    editpost: "/post/edit/",
    page: "/page",
    product: "/product",
    addproduct: "/product/add",
    editproduct: "/product/edit/",
    category: "/category",
    addcategory: "/category/add",
    editcategory: "/category/edit/",
    media: "/media",
    user: "/user",
    adduser: "/user/add",
    edituser: "/user/edit/",
    role: "/role",
    partner: "/partner",
    addpartner: "/partner/add",
    editpartner: "/partner/edit/",
    timeline: "/timeline",
    addtimeline: "/timeline/add",
    edittimeline: "/timeline/edit/",
    research: "/research",
    addresearch: "/research/add",
    editresearch: "/research/edit/",
    setting: "/setting",
    // auth
    login: "/login",
    productlite: "/productlite",
    addproductlite: "/productlite/add",
    editproductlite: "/productlite/edit/",
};
