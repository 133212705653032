import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { NavLink } from "react-router-dom";
import SLUGS from "../../resources/slugs";
import {
    MdDashboard,
    BiCommentDetail,
    BiUser,
    BiCog,
    FaRegHandshake,
    MdTimeline,
    BsBook,
    BiCustomize,
    RiProductHuntLine,
} from "react-icons/all";
import LogoComponent from "./LogoComponent";
import Menu from "./MenuComponent";

const useStyles = createUseStyles((theme) => ({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06,
    },
    NavLink: {
        display: "flex!important",
        alignItems: "center",
        height: 56,
        cursor: "pointer",
        paddingLeft: ({ level }) => 32 * level,
        transition: "all 0.2s ease-in-out",
    },
    leftBar: {
        borderLeft: `4px solid ${theme.color.hoverColor}`,
        "& $title": {
            color: theme.color.hoverColor,
        },
    },
    title: {
        fontSize: 16,
        lineHeight: "20px",
        letterSpacing: "0.2px",
        color: theme.color.primaryColor,
        marginLeft: 24,
    },
}));

function SidebarComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <NavLink
                to={SLUGS.dashboard}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <MdDashboard />
                </span>
                <span className={classes.title}>Dashboard</span>
            </NavLink>

            {/* <NavLink
                to={SLUGS.page}   
                className={classes.NavLink}
                activeClassName={classes.leftBar}
                >
                <span className={classes.title}><BiBookAlt/></span>
                <span className={classes.title}>Page</span>
                </NavLink>  */}

            <NavLink
                to={SLUGS.post}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <BiCommentDetail />
                </span>
                <span className={classes.title}>Post</span>
            </NavLink>

            {/* <NavLink
                to={SLUGS.media}   
                className={classes.NavLink}
                activeClassName={classes.leftBar}
                >
                <span className={classes.title}><BiImages/></span>
                <span className={classes.title}>Media</span>
                </NavLink>  */}

            {/* <NavLink
                to={SLUGS.product}   
                className={classes.NavLink}
                activeClassName={classes.leftBar}
                >
                <span className={classes.title}><BiShoppingBag/></span>
                <span className={classes.title}>Product</span>
                </NavLink>   */}

            <NavLink
                to={SLUGS.timeline}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <MdTimeline />
                </span>
                <span className={classes.title}>Timeline</span>
            </NavLink>
            <NavLink
                to={SLUGS.research}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <BsBook />
                </span>
                <span className={classes.title}>Research</span>
            </NavLink>
            <NavLink
                to={SLUGS.productlite}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <RiProductHuntLine />
                </span>
                <span className={classes.title}>Product</span>
            </NavLink>
            <NavLink
                to={SLUGS.partner}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <FaRegHandshake />
                </span>
                <span className={classes.title}>Partner</span>
            </NavLink>

            <NavLink
                to={SLUGS.category}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <BiCustomize />
                </span>
                <span className={classes.title}>Category</span>
            </NavLink>

            <NavLink
                to={SLUGS.user}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <BiUser />
                </span>
                <span className={classes.title}>User Management</span>
            </NavLink>

            <NavLink
                to={SLUGS.setting}
                className={classes.NavLink}
                activeClassName={classes.leftBar}
            >
                <span className={classes.title}>
                    <BiCog />
                </span>
                <span className={classes.title}>Setting</span>
            </NavLink>
        </Menu>
    );
}

export default SidebarComponent;
