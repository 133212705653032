import React, { useEffect } from 'react';
import { useLocation, Route } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import PrivateSection from './PrivateSection';
import PublicRoutes from './PublicRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getToken } from '../resources/auth';

require('dotenv').config()

function Routes(...rest) {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Route
        {...rest}
        render={(props) => getToken() ? <PrivateSection/> : <PublicRoutes/>}
        />
    )
}

export default Routes;