import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Col, Row } from "react-bootstrap";
import { SidebarComponent, SidebarContext } from "../components/sidebar";
import HeaderComponent from "../components/header/HeaderComponent";
import PrivateRoutes from "./PrivateRoutes";

const useStyles = createUseStyles({
    container: {
        height: "100%",
        minHeight: "100vh",
    },
    content: {
        backgroundColor: ({ theme }) => `${theme.color.bgContent}`,
        padding: "30px",
        minHeight: "100%",
        boxShadow: "0px 0px 24px rgba(112, 144, 176, 0.15)",
        borderRadius: 20,
        marginRight: 20,
        marginLeft: 275,
        "@media (max-width: 1080px)": {
            marginLeft: 0,
            marginRight: 0,
            padding: 10,
        },
    },
    mainBlock: (props) => ({
        display: "block",
        backgroundColor: props.bgContent,
        zIndex: "999",
        color: props.veryDarkGrayishBlue,
        padding: 30,
        flexGrow: 2,
        marginLeft: 255,
        "@media (max-width: 1080px)": {
            marginLeft: 0,
            padding: 10,
        },
    }),
});

function PrivateSection() {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <SidebarContext>
            <Col className={classes.container}>
                <SidebarComponent />
                <Row className={classes.mainBlock}>
                    <HeaderComponent />
                </Row>
                <Row className={classes.content}>
                    <PrivateRoutes />
                </Row>
            </Col>
        </SidebarContext>
    );
}

export default PrivateSection;
