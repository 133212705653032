import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import Theme from "./resources/theme";
import Routes from "./routes";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "react-datepicker/dist/react-datepicker.css";

import "suneditor/dist/css/suneditor.min.css";

ReactDOM.render(
    <ThemeProvider theme={Theme}>
        <Router>
            <Routes />
        </Router>
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
