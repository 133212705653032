import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from '../resources/slugs';
import LoadingComponent from '../components/loading/LoadingComponent';

const LoginComponent = lazy(() => import('./auth/LoginComponent'));

function PublicRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route path={SLUGS.login} component={LoginComponent}/>
                <Redirect to={SLUGS.login} />
            </Switch>
        </Suspense>
    );
}

export default PublicRoutes;
