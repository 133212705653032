import React from "react";
import {
    arrayOf,
    element,
    func,
    oneOfType,
    shape,
    string,
} from "prop-types";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
    dropdown: {
        "& .btn-primary": {
            backgroundColor: "transparent",
            borderColor: "transparent",
            color: theme.color.primaryColor,
        },
        "& .btn-primary.dropdown-toggle": {
            backgroundColor: "transparent",
            borderColor: "transparent",
        },
        "& .btn-primary.dropdown-toggle:focus": {
            color: theme.color.primaryColor,
            boxShadow: "none",
            backgroundColor: theme.color.hoverColor,
        },
    },
}));

function DropdownComponent({ label, options, position }) {
    const theme = useTheme();
    const classes = useStyles({ theme });

    function onItemClick(onClick) {
        onClick && onClick();
    }
    return (
        <DropdownButton
            className={classes.dropdown}
            menuAlign={position}
            title={label}
            id="dropdown-menu-align-left"
        >
            {options.map((option, index) => (
                <Dropdown.Item
                    key={index}
                    eventKey={index}
                    onClick={() => onItemClick(option.onClick)}
                >
                    {option.label}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}

DropdownComponent.propTypes = {
    label: oneOfType([string, element]),
    options: arrayOf(
        shape({
            label: oneOfType([string, arrayOf(element)]),
            to: func,
        })
    ),
    position: oneOfType([string, element]),
};

export default DropdownComponent;
