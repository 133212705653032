import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SLUGS from "../resources/slugs";
import LoadingComponent from "../components/loading/LoadingComponent";

const DashboardComponent = lazy(() => import("./dashboard"));
const PostComponent = lazy(() => import("./post"));
const UserComponent = lazy(() => import("./user"));
const PartnerComponent = lazy(() => import("./partner"));
const TimelineComponent = lazy(() => import("./timeline"));
const AddTimelineComponent = lazy(() => import("./timeline/form/AddComponent"));
const EditTimelineComponent = lazy(() =>
    import("./timeline/form/EditComponent")
);
const ResearchComponent = lazy(() => import("./research"));
const AddResearchComponent = lazy(() => import("./research/form/AddComponent"));
const EditResearchComponent = lazy(() =>
    import("./research/form/EditComponent")
);

const AddUserComponent = lazy(() => import("./user/form/AddComponent"));
const EditUserComponent = lazy(() => import("./user/form/EditComponent"));
const AddPostComponent = lazy(() => import("./post/form/AddComponent"));
const EditPostComponent = lazy(() => import("./post/form/EditComponent"));
const ProductComponent = lazy(() => import("./product/ProductComponent"));
const AddProductComponent = lazy(() => import("./product/form/AddComponent"));
const EditProductComponent = lazy(() => import("./product/form/EditComponent"));
const ProductLiteComponent = lazy(() =>
    import("./productLite/ProductLiteComponent")
);
const AddProductLiteComponent = lazy(() =>
    import("./productLite/form/AddComponent")
);
const EditProductLiteComponent = lazy(() =>
    import("./productLite/form/EditComponent")
);
const CategoryComponent = lazy(() => import("./category/CategoryComponent"));
const AddCategoryComponent = lazy(() => import("./category/form/AddComponent"));
const EditCategoryComponent = lazy(() =>
    import("./category/form/EditComponent")
);
const AddPartnerComponent = lazy(() => import("./partner/form/AddComponent"));
const EditPartnerComponent = lazy(() => import("./partner/form/EditComponent"));

const SettingComponent = lazy(() => import("./setting/SettingComponent"));

function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route
                    exact
                    path={SLUGS.dashboard}
                    component={DashboardComponent}
                />
                <Route exact path={SLUGS.post} component={PostComponent} />
                <Route
                    exact
                    path={SLUGS.addpost}
                    component={AddPostComponent}
                />
                <Route
                    exact
                    path={SLUGS.editpost + ":id"}
                    component={EditPostComponent}
                />
                <Route
                    exact
                    path={SLUGS.media}
                    render={() => <div>overviewTwo</div>}
                />
                <Route
                    exact
                    path={SLUGS.page}
                    render={() => <div>overviewTwo</div>}
                />
                <Route exact path={SLUGS.user} component={UserComponent} />
                <Route
                    exact
                    path={SLUGS.adduser}
                    component={AddUserComponent}
                />
                <Route
                    exact
                    path={SLUGS.edituser + ":id"}
                    component={EditUserComponent}
                />
                {/* <Route
                    exact
                    path={SLUGS.product}
                    component={ProductComponent}
                />
                <Route
                    exact
                    path={SLUGS.addproduct}
                    component={AddProductComponent}
                />
                <Route
                    exact
                    path={SLUGS.editproduct + ":id"}
                    component={EditProductComponent}
                /> */}
                <Route
                    exact
                    path={SLUGS.productlite}
                    component={ProductLiteComponent}
                />
                <Route
                    exact
                    path={SLUGS.addproductlite}
                    component={AddProductLiteComponent}
                />
                <Route
                    exact
                    path={SLUGS.editproductlite + ":id"}
                    component={EditProductLiteComponent}
                />
                <Route
                    exact
                    path={SLUGS.category}
                    component={CategoryComponent}
                />
                <Route
                    exact
                    path={SLUGS.addcategory}
                    component={AddCategoryComponent}
                />
                <Route
                    exact
                    path={SLUGS.editcategory + ":id"}
                    component={EditCategoryComponent}
                />
                <Route
                    exact
                    path={SLUGS.partner}
                    component={PartnerComponent}
                />
                <Route
                    exact
                    path={SLUGS.addpartner}
                    component={AddPartnerComponent}
                />
                <Route
                    exact
                    path={SLUGS.editpartner + ":id"}
                    component={EditPartnerComponent}
                />
                <Route
                    exact
                    path={SLUGS.timeline}
                    component={TimelineComponent}
                />
                <Route
                    exact
                    path={SLUGS.addtimeline}
                    component={AddTimelineComponent}
                />
                <Route
                    exact
                    path={SLUGS.edittimeline + ":id"}
                    component={EditTimelineComponent}
                />
                <Route
                    exact
                    path={SLUGS.research}
                    component={ResearchComponent}
                />
                <Route
                    exact
                    path={SLUGS.addresearch}
                    component={AddResearchComponent}
                />
                <Route
                    exact
                    path={SLUGS.editresearch + ":id"}
                    component={EditResearchComponent}
                />
                <Route
                    exact
                    path={SLUGS.setting}
                    component={SettingComponent}
                />
                <Redirect to={SLUGS.dashboard} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
